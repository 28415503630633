import React, { useEffect } from 'react'
import useExternalScript from '../../hooks/use-external-script';

export const ContactForm = () => {
  //CONTACT FORM SCRIPTS
  useExternalScript('https://www.google.com/recaptcha/api.js', {async: true, defer: true});
  useExternalScript('https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=f2f5311914b78a71526baebcc1e777c699a0afa83310571e39b31cb19193e9a6gidc11eaf6f80f1dad81f22c2268b397e1912b473a9775fc1518fc3092147ffa642gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a', {id: "wf_anal"});

  function formatearFecha(fecha) {
    // Obtener día, mes y año de la fecha
    const dia = fecha.getDate().toString().padStart(2, '0'); // Asegurar que tenga 2 dígitos
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // El mes es base 0, por eso sumamos 1
    const año = fecha.getFullYear();
  
    // Combinar en el formato deseado
    const fechaFormateada = `${dia}/${mes}/${año}`;
  
    return fechaFormateada;
  }

  useEffect(() => {
    var date = new Date();
    // console.log("fomateo de fecha", formatearFecha(date));
    date.setDate(date.getDate() + 1);
    document.getElementById('LEADCF117').value = formatearFecha(date);

    // Sumamos otro día
    date.setDate(date.getDate() + 1);
    document.getElementById('LEADCF116').value = formatearFecha(date);
  }, []);

  return (
    <div className="modal fade" id="modal_contactanos" tabIndex={-1} role="dialog" aria-labelledby="modal_contactanos_Label">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" > 
            <div className="modal-header">
              {/* <!-- <h4 className="modal-title" id="modal_contactanos_Label">CONTÁCTANOS</h4> --> */}
              <h2 className='text-center section-heading title-hotel text-center text-white w-100' data-i18n="contactUs">Contáctanos</h2>
              <button className="ariesClose" data-dismiss="modal" aria-label="Close">
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
              </button>
            </div>
            <div className="modal-body">
              
              <div id='crmWebToEntityForm' className='zcwf_lblLeft crmWebToEntityForm designWhite text-hotel'>
                {/* <meta name='viewport' content='width=device-width, initial-scale=1.0'> */}
                {/* <META HTTP-EQUIV='content-type' CONTENT='text/html;charset=UTF-8'> */}
                {/* <script src='https://www.google.com/recaptcha/api.js' async defer></script> */}
                <form id="webform1552583000132566001" action='https://crm.zoho.com/crm/WebToLeadForm' name="WebToLeads1552583000132566001" method='POST'
                  onSubmit={(e) => {return window.checkMandatory1552583000132566001(e)}} acceptCharset='UTF-8'>
                  <input type='text' className='d-none' name='xnQsjsdp'
                    defaultValue='c11eaf6f80f1dad81f22c2268b397e1912b473a9775fc1518fc3092147ffa642'>
                  </input>
                  <input type='hidden' name='zc_gad' id='zc_gad' defaultValue=''></input>
                  <input type='text' className='d-none' name='xmIwtLD'
                    defaultValue='f2f5311914b78a71526baebcc1e777c699a0afa83310571e39b31cb19193e9a6'></input>
                  <input type='text' className='d-none' name='actionType' defaultValue='TGVhZHM='></input>
                  <input type='text' className='d-none' name='returnURL'
                    defaultValue='https&#x3a;&#x2f;&#x2f;bellafer.com&#x2f;'></input>
                              
                  <div className="d-flex flex-wrap">
                    <div className='zcwf_row col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'>
                        <label htmlFor='First_Name' data-i18n="name">Nombre</label><span className='text-danger'>*</span>
                      </div>
                      <div className='zcwf_col_fld'>
                        <input type='text' id='First_Name' name='First Name' maxLength={40} required></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                    <div className='zcwf_row col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'><label htmlFor='Last_Name' data-i18n="lastName">Apellido</label><span className='text-danger'>*</span>
                      </div>
                      <div className='zcwf_col_fld'><input type='text' id='Last_Name' name='Last Name' maxLength={80} required></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className='zcwf_row  col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'><label htmlFor='Phone' data-i18n="phone">Teléfono</label><span className='text-danger'>*</span></div>
                      <div className='zcwf_col_fld'><input type='text' id='Phone' name='Phone' maxLength={30} required></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                    <div className='zcwf_row  col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'><label htmlFor='Email' data-i18n="email">Email</label><span className='text-danger'>*</span></div>
                      <div className='zcwf_col_fld'><input type='text' data-ftype='email' id='Email' name='Email' maxLength={100} required></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none flex-wrap">
                    <div className='zcwf_row col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF52'>Numero de
                          adultos<span>*</span></label></div>
                      <div className='zcwf_col_fld'><input type='text' id='LEADCF52' name='LEADCF52' maxLength={9} defaultValue="0"></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                    <div className='zcwf_row col-lg-6 col-sm-12 d-none'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF51'>Numero de
                          niños<span>*</span></label></div>
                      <div className='zcwf_col_fld'><input type='text' id='LEADCF51' name='LEADCF51' maxLength={9} defaultValue="0"></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className='zcwf_row  col-lg-6 col-sm-12 d-none'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF117'>Fecha de
                          Check-in<span className='color-danger'>*</span></label></div>
                      <div className='zcwf_col_fld'>
                        <input type='text' id='LEADCF117' name='LEADCF117' maxLength={120} ftype='date' placeholder='DD/MM/YYYY'
                          tplid='1552583000132566001LEADCF117' min="ObtenerMinFecha()"
                          data-onfocus='formCalender.stEv(event);formCalender.createCalendar(this);' autoComplete='off'></input>
                        <div id='template1552583000132566001LEADCF117' data-onclick='formCalender.stEv(event);' className='tempCalDiv'></div>
            
                        <div className='zcwf_col_help'></div>
                      </div>
            
                    </div>
                    <div className='zcwf_row  col-lg-6 col-sm-12 d-none'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF117'>Hora de
                          Check-in</label></div>
                      <div className='zcwf_col_fld'>
                        <div className="d-flex" style={{minHeight: 28}}>
                          <select name='LEADCF117hour' defaultValue="03">
                            {/* <!-- <option value='01'>01</option>
                            <option value='02'>02</option> --> */}
                            <option value='03'>03</option>
                            <option value='04'>04</option>
                            <option value='05'>05</option>
                            <option value='06'>06</option>
                            <option value='07'>07</option>
                            <option value='08'>08</option>
                            <option value='09'>09</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            {/* <!-- <option value='12'>12</option> --> */}
                          </select>
                          <select name='LEADCF117minute' defaultValue="00">
                            <option value='00'>00</option>
                            <option value='15'>15</option>
                            <option value='30'>30</option>
                            <option value='45'>45</option>
                          </select> */
                          <input type="text" name='LEADCF117ampm' defaultValue="PM" readOnly style={{width: 45, textAlign: "center"}} />
            
                          {/* <!--  <select name='LEADCF117ampm'>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                          </select> --> */}
                        </div>
                      </div>
            
                    </div>
                  </div>
            
            
                  <div className="d-none flex-wrap ">
                    <div className='zcwf_row  col-lg-6 col-sm-12'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF116'>Fecha de
                          Check-out<span className='text-danger'>*</span></label></div>
                      <div className='zcwf_col_fld'><input type='text' id='LEADCF116' name='LEADCF116' maxLength={120} ftype='date'
                          placeholder='DD/MM/YYYY' tplid='1552583000132566001LEADCF116'
                          data-onfocus='formCalender.stEv(event);formCalender.createCalendar(this);' autoComplete='off' defaultValue="01/01/2024"></input>
                        <div id='template1552583000132566001LEADCF116' data-onclick='formCalender.stEv(event);' className='tempCalDiv'>
                        </div>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
            
                    {/* <!-- </div>
                  <div className="d-flex flex-wrap"> --> */}
                    <div className='zcwf_row  col-lg-6 col-sm-12 d-none'>
                      <div className='zcwf_col_lab'><label htmlFor='LEADCF116'>Hora de
                          Check-out</label></div>
                      <div className='zcwf_col_fld'>
                        <div className="d-flex" style={{minHeight: 28}}>
                          <input type="text" name='LEADCF116hour' defaultValue="12" readOnly style={{width: 45, textAlign: "center"}} />
                          <input type="text" name='LEADCF116minute' defaultValue="00" readOnly style={{width: 45, textAlign: "center"}} />
                          <input type="text" name='LEADCF116ampm' defaultValue="PM" readOnly style={{width: 45, textAlign: "center"}} />
                          {/* <!-- <select name='LEADCF116hour'>
                            <!- - <option value='01'>01</option>
                            <option value='02'>02</option>
                            <option value='03'>03</option>
                            <option value='04'>04</option>
                            <option value='05'>05</option>
                            <option value='06'>06</option>
                            <option value='07'>07</option>
                            <option value='08'>08</option>
                            <option value='09'>09</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option> -- >
                            <option value='12'>12</option>
                          </select>
                          <select name='LEADCF116minute' value="00">
                            <option value='00'>00</option>
                          </select>
                          <select name='LEADCF116ampm'>
                            <!- - <option value='AM'>AM</option> - ->
                            <option value='PM'>PM</option>
                          </select> --> */}
            
            
                        </div>
                      </div>
                    </div>
                    {/* <!-- </div> --> */}
            
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className='zcwf_row col-lg-12'>
                      <div className='zcwf_col_lab'>
                        <label htmlFor='Description' data-i18n="comment">Nota/Comentario</label><span className='text-danger'>*</span>
                      </div>
                      <div className='zcwf_col_fld'>
                        <textarea id='Description' name='Description' rows={6} required></textarea>
                        <div className='zcwf_col_help'></div>
                      </div>
                      {/* <!-- <div className='zcwf_row wfrm_fld_dpNn'>
                      </div> --> */}
            
                    </div>
                  </div>
            
                  <div className="d-none flex-wrap">
                    <div className='zcwf_row col-lg-12'>
                      <div className='zcwf_col_lab'>
                        <label htmlFor='Lead_Source'>Prospecto Source</label>
                      </div>
                      <div className='zcwf_col_fld'>
                        <select className='zcwf_col_fld_slt' id='Lead_Source' name='Lead Source' defaultValue="Bellafer&#x20;Web">
                          <option value='Bellafer&#x20;Web'>Bellafer Web</option>
                        </select>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none flex-wrap">
                    <div className='zcwf_row col-lg-12'>
                      <div className='zcwf_col_lab'>
                        <label htmlFor='LEADCF16'>FormularioUbicacion</label>
                      </div>
                      <div className='zcwf_col_fld'>
                        <input type='text' id='LEADCF16' name='LEADCF16' maxLength={255} defaultValue='BellaferWeb'></input>
                        <div className='zcwf_col_help'></div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- 
                  <div className='zcwf_row'>
                    <div className='zcwf_col_lab'></div>
                    <div className='zcwf_col_fld'>
                      <div className='g-recaptcha' data-sitekey='6Lc_mjgUAAAAAHxbH5MOw-H' data-theme='dark'
                        data-callback='rccallback1552583000132566001' captcha-verified='false'
                        id='recap1552583000132566001'></div>
                      <div id='recapErr1552583000132566001' data-style='font-size:12px;color:red;visibility:hidden;'>Captcha
                        validation failed. If you are not a robot then please try again.</div>
                    </div>
                  </div>--> */}
            
                  <div className='zcwf_row'>
                    <div className='zcwf_col_lab'></div>
                    <div className='zcwf_col_fld'>
                      <div className='g-recaptcha m-auto' style={{width: "fit-content"}}
                        data-sitekey='6Lc_mjgUAAAAAHxbH5MOw-Hz-3cvX56Xrrex3JEl' data-theme='light'
                        data-callback='rccallback1552583000132566001' captcha-verified='false'
                        id='recap1552583000132566001'></div>
                      <div id='recapErr1552583000132566001' style={{
                        fontSize: 14,
                        textShadow: "1px 2px 3px #84651b",
                        color: "red",
                        textAlign: "center",
                        visibility: "hidden"
                      }}>
                        Validación de Captcha fallida. Si no es un robot, inténtelo de nuevo.
                      </div>
                    </div>
                  </div>
            
            
                  {/* <!-- <div className='zcwf_row'>
                    <div className='zcwf_col_lab'></div>
                    <div className='zcwf_col_fld'><input type='submit' id='formsubmit' className='formsubmit zcwf_button'
                        value='Submit' title='Submit'><input type='reset' className='zcwf_button' name='reset' value='Reset'
                        title='Reset'>
                    </div>
                  </div> --> */}
            
            
                  {/* <!-- <div className='zcwf_row'> --> */}
                    {/* <!-- <div className='zcwf_col_lab'></div> --> */}
                    <div style={{
                      display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 15, padding: "15px 20px"
                    }}>
                      {/* <!-- <button type="button" className="zcwf_button btn" data-dismiss="modal" data-style="text-transform: uppercase;">Cerrar</button> --> */}
                      <button type='submit' id='formsubmit' className='formsubmit zcwf_button btn' value='Enviar' title='Enviar' data-i18n="send">Enviar</button>
                      {/* <!-- <input type='reset' data-style="display: none;" className='zcwf_button' name='reset' value='Reset' title='Reset'> --> */}
                    {/* <!-- </div> -->  */}
                  </div>
            
            
                    {/* TODO: Activar */}
                  {/* <?php if ($_SERVER["HTTP_HOST"] == "www.bellafer.com" || $_SERVER["HTTP_HOST"] == "bellafer.com"): ?>
                    <!-- </script> Do not remove this --- Analytics Tracking code starts -->      
                    <script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=f2f5311914b78a71526baebcc1e777c699a0afa83310571e39b31cb19193e9a6gidc11eaf6f80f1dad81f22c2268b397e1912b473a9775fc1518fc3092147ffa642gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a'></script>
                    <!-- Do not remove this --- Analytics Tracking code ends. -->
                  <?php endif; ?> */}
                </form>
              </div>



            </div>
            {/* <!-- <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div> --> */}
          </div>
        </div>
      </div>
  )
}
