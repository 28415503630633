import { useEffect } from 'react'

type Options = {
    async?: boolean,
    defer?: boolean
    id?: string,
    onLoaded?: () => void
}

const defaultOptions = {
    async: false, 
    defer: false,
    onLoaded: () => {}
}

/**
 * Importar archivos Javascript externos que utilicen Vanilla JS, jQuery u otros.
 * @param src fuente o url del archivo externero 
 * @param async parametro async de html script tag
 */
const useExternalScript = (src:string, options: Options = defaultOptions) => {
  useEffect(() =>{
    const script = document.createElement('script');
    script.src = src;

    if (options.async) {
        script.async = true;
    }
    if (options.defer) {
        script.defer = true;
    }
    if (options.id) {
        script.id = options.id;
    }

    document.body.appendChild(script);

    if (options.onLoaded) {
      options.onLoaded();
    }

    return () => {
      // Limpiar el script si el componente se desmonta
      document.body.removeChild(script);
    };
  }, []);
}

export default useExternalScript