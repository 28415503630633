exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-privacidad-tsx": () => import("./../../../src/pages/aviso-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-tsx" */),
  "component---src-pages-facturacion-jsx": () => import("./../../../src/pages/facturacion.jsx" /* webpackChunkName: "component---src-pages-facturacion-jsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-politica-cookies-tsx": () => import("./../../../src/pages/politica-cookies.tsx" /* webpackChunkName: "component---src-pages-politica-cookies-tsx" */),
  "component---src-pages-terminos-condiciones-tsx": () => import("./../../../src/pages/terminos-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-condiciones-tsx" */)
}

