import React, { PropsWithChildren, useEffect } from "react"
import Navbar from "../components/navbar/navbar"
import Footer from "../components/footer/footer"

import "../../static/vendor/bootstrap/css/bootstrap.min.css"
import "../../static/vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
import "../assets/css/creative.css"
import "../assets/css/index.css"
import "../assets/css/global.scss"
import useExternalScript from "../hooks/use-external-script";
import { Link } from "gatsby"
import { Button } from "@mui/material"
import { ArrowUpwardRounded } from "@mui/icons-material"

export default function MainLayout(props:PropsWithChildren) {
  //Creative Script
  useExternalScript("/assets/js/creative.min.js");
  
  return (
    <div id="inicio">
        <Navbar />
        { props.children }
        <Footer />
        <Link to={"#inicio"}>
          <Button className={"btntoHeader"}>
            <ArrowUpwardRounded></ArrowUpwardRounded>
          </Button>
        </Link>
    </div>
  )
}