import { Link } from 'gatsby'
import React from 'react'

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div className="collapse navbar-collapse w-100" id="navbarSupportedContent">
        </div>
      <div className="mainNavbar">

        <div className="logoDiv d-flex justify-content-sm-start justify-content-md-center w-100">
          <Link className="navbar-brand" to="/"><img src="/img/BellaferHotelsandResorts_black.png" style={{height: 66}} /></Link>
        </div>
        <button className="navbar-toggler" style={{height: "fitContent"}} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
        <div className="collapse navbar-collapse w-100 justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto text-left ulNavbar">
            {/* <!-- <li className="nav-item active">
                <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
              </li> --> */}
            <li className="nav-item">
              <a className="nav-link pb-0 text-capitalize" href="/galeria" data-i18n="gallery">Galería</a>
            </li>
            <li className="nav-item">
              <a href="http://puntazul.com" target="_blank" className="nav-link pb-0 text-capitalize">
                Real Estate
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pb-0 text-capitalize" href="#" data-toggle="modal" data-target="#modal_contactanos" data-i18n="contactUs">Contáctanos</a>
            </li>
            <li className="nav-item">
              <select className="selectpicker pi-0" id="language-select" data-width="fit" data-style="custom-select-picker">
                <option value="es" data-content="<span class='fi fi-mx'></span> ES">ES</option>
                <option value="en" data-content="<span class='fi fi-us'></span> EN">EN</option>
              </select>
            </li>
          </ul>
        </div>
      </nav>
  )
}

export default Navbar