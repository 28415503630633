import { Link } from 'gatsby';
import React from 'react'
import { ContactForm } from '../contact-form/contact-form';

const Footer = () => {
  let year = new Date().getFullYear().toString();

  return (
    <div>
      <footer id="contact">
        <section className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <a href="" data-toggle="modal" data-target="#modal_contactanos">
                <h2 className="section-heading" data-i18n="contactUs">Contáctanos</h2>
              </a>
              <hr className="my-4" />

            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center">
              <a href="tel:+526611520029">
                <i className="fas fa-phone fa-4x mb-3 sr-contact-1"></i></a>
              <p>
                <small data-i18n="frontDesk">Recepción</small>
                <br />
                <span data-i18n="salesIcon"></span><br/>
                <a href="tel:+526611520029">+52 (661) 152-0029</a>
              </p>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <a href="mailto:book@bellafer.com">
                <i className="fas fa-envelope fa-4x mb-3 sr-contact-2"></i>
              </a>            
              <p>
                <small></small>
                <br/>
                <span data-i18n="informationIcon">Información:</span><br/>
                <a href="mailto:book@bellafer.com">book@bellafer.com</a>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center py-5">
            <a href="https://www.facebook.com/BellaferHotels" target="_blank" rel="noopener noreferrer" className="social-icon-link facebook-icon-link">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/bellaferhotels/" target="_blank" rel="noopener noreferrer" className="social-icon-link instagram-icon-link">
              <span className="instagram-icon-link-overlay"></span>
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@bellaferhotels" target="_blank" rel="noopener noreferrer" className="social-icon-link tiktok-icon-link">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </section>
        <a href="https://wa.me/+526611178778" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
          <i className="fab fa-whatsapp"></i>
        </a>
        <div className="footer text-center" style={{paddingBlock: "2rem"}}>
            <span className="row container justify-content-center m-auto py-3">
              <Link to="/menu" className="col-sm-12 col-md-5 col-lg-2" data-i18n="menu">Menú</Link>
              <Link to="/facturacion" className="col-sm-12 col-md-5 col-lg-2" data-i18n="billing">Facturación</Link>
              <Link to="/terminos-condiciones" className="col-sm-12 col-md-4 col-lg-3" data-i18n="termsAndConditions">Términos y condiciones</Link>
              <Link to="/aviso-privacidad" className="col-sm-12 col-md-4 col-lg-3" data-i18n="privacyNotice">Aviso de Privacidad</Link>
              <Link to="/politica-cookies" className="col-sm-12 col-md-4 col-lg-2" data-i18n="cookiePolicy">Política de Cookies</Link>
            </span>
          <div className="container">
            <p><i className="fas fa-map-marker sr-contact-1"></i> Aguamarina No. 10, Fracc. Puntazul Zafiro, Playas de Rosarito, B.C.</p>
            <p>Copyright { year } © <span className="text-nowrap">Bellafer Collection Hotels & Resorts</span> - Derechos reservados</p>
          </div>
        </div>
      </footer>
      <ContactForm/>
    </div>
  )
}

export default Footer