import { useEffect } from 'react'
import useExternalScript from './use-external-script';

const useAnalitycsTags = () => {
  useEffect(() => {
    if (window.location.host.includes("bellafer.com")) {
      useScriptGoogle();
      useFbPixel();
      useFbPixelNoScript();
      useScriptZoho("https://salesiq.zohopublic.com/widget","siq12a33e350a36bd410d982a1f697b7bca9cffaf2de61e323f7b32314da13670e60628189a4adb818d6978f8875c8482e3");
      useExternalScript("https://tools.luckyorange.com/core/lo.js?site-id=fc0b9b50", {async: true, defer: true});
    }
  }, [])
}

const useScriptGoogle = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");
    
        let code = `window.dataLayer = window.dataLayer || []; 
        function gtag(){
          dataLayer.push(arguments);
        } 
        gtag('js', new Date()); gtag('config', 'G-EW77CW1TVG');
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id="tagAnaliticsScript";
          s.defer=true;
          s.src="https://www.googletagmanager.com/gtag/js?id=G-EW77CW1TVG";
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
          d.innerHTML = "<div id='tagAnalitics'></div>";`
    
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    }, []);
};

const useFbPixel = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");

        let code = `
        !function(f,b,e,v,n,t,s)
        { 
          if(f.fbq)return;
          n=f.fbq = function(){
            n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)
        };
          if(!f._fbq)
          f._fbq=n;
          n.push=n;
          n.loaded=!0;
          n.version='2.0';
          n.queue=[];
          t=b.createElement(e);
          t.async=!0;
          t.src=v;
          s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)
        }
        (window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '413057468094745'); 
          fbq('track', 'PageView');
    `
    
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    }, []);
};
  
  const useFbPixelNoScript = () => {
    useEffect(() => {
        const script = document.createElement('noscript');
        let noscript = '<img height="1" width="1" src="https://www.facebook.com/tr?id=413057468094745&ev=PageView&noscript=1"/>'
    
        script.innerHTML += noscript;
        var d = document;
        let s = d.getElementsByTagName("body")[0];
        if (s.parentNode) {
          s.parentNode.insertBefore(script, s);
          document.body.appendChild(script);
        }
    
        return () => {
          document.body.removeChild(script);
        }
    }, []);
};

const useScriptZoho = (scriptSrc:any, widgetCode: any) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");
    
        let code = `
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: "${widgetCode}", values:{},ready:function(){}
          };
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="${scriptSrc}";
          
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
          d.innerHTML = "<div id='zsiqwidget' ></div>";
        `;
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    }, []);
  };

export default useAnalitycsTags